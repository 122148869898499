<template>
  <div @click="handleSelectedIndustry"
       :class="[title === selectedIndustryName ? 'industry-btn industry-btn-active' : 'industry-btn']"
       class="tw-p-5">
    <div class="tw-flex tw-justify-between tw-mt-2 ">
      <p>{{ title }}</p>
      <div class="lg:tw-hidden">
        <ChevronRightIcon />
      </div>
    </div>
    <!--  <div v-if="industry.roles.length > 0 && title === selectedIndustryName" class="tw-flex tw-flex-wrap  lg:tw-hidden tw-pb-5">-->
    <!--    <div class="tw-mt-5 tw-mr-5" v-for="role in industry.roles" :key="role" @click="handleSelectedIndustry('btn',role)"-->
    <!--         :class="[selectedIndustryRole === role ? 'selected' : 'select']">{{role}}</div>-->
    <!--    <div class="tw-mt-5" v-if="selectedIndustryRole === 'Others'">-->
    <!--      <p class="skills">Others</p>-->
    <!--      <v-text-field-->
    <!--          hide-details-->
    <!--          placeholder="Please specify the Role"-->
    <!--          v-model="customMobileRole"-->
    <!--          class="tw-w-auto tw-h-14"-->
    <!--          solo-->
    <!--          id="specifyOtherMobile"></v-text-field>-->
    <!--    </div>-->
    <!--  </div>-->
  </div>
</template>

<script>
import {ChevronRightIcon} from "vue-feather-icons"

export default {
  name: "IndustryBtn",
  components: {ChevronRightIcon},
  props: {
    title: [String],
    industry: Object,
    selectedIndustryName: [String],
  },
  data() {
    return {
      selectedIndustryRole: '',
      customMobileRole: '',
    }
  },
  watch: {
    'customMobileRole': {
      handler: function (value) {
        this.$emit('handleSelectedRole', value)
      }
    }
  },
  methods: {
    handleSelectedIndustry(type, role) {
      this.$emit('takeIndustry')
      if (type === 'btn') {
        this.handleSelectedRole(role)
      } else {
        this.$emit('handleSelectedIndustryName', this.title)

      }
    },

    handleSelectedRole(role) {
      this.selectedIndustryRole = role
    },
  }
}
</script>

<style scoped>
.industry-btn {
  padding: 10px;
  box-sizing: border-box;
  border-radius: 8px;
  cursor: pointer;
}

.industry-btn-active {
  border: 1px solid #F3F3F3;
}

.industry-text {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  align-items: center;
  color: #475661;
}
</style>