<template>
  <job-posting-layout>
    <template #topContent>
      <div class="stepContent tw-w-full">
        <step-count :count="1"/>
        <step-title title="What role are you looking to fill?"/>
        <label class="tip">Start by selecting an industry, then select a role from available options.</label>
        <div class="tw-mt-10 tw-flex">
          <div class="tw-w-full lg:tw-w-3/12">
            <p class="industry" v-if="!$vuetify.breakpoint.mobile">Select Industry</p>
            <div class="tw-flex-col tw-w-full tw-flex ">
              <v-text-field :loading="loading" placeholder="Search Industry" v-model="searchIndustry" solo
                            hide-details>
                <template v-slot:prepend-inner>
                  <vue-feather style="color: #9CABB5" type="search"/>
                </template>
              </v-text-field>
                          <div v-if="searchResult.length <= 0 && !loading">
                            <span style="color: red"> No match found</span>
                          </div>
              <div class="lg:tw-hidden tw-w-full">
                <small style="color: red" v-if="showError.state">{{ showError.message }}</small>
                <div v-if="selectedIndustryName === 'Others'" class="tw-flex tw-w-full tw-flex-col">
                  <h6 class="custom-industry-label tw-mt-5 tw-mb-3">Industry</h6>
                  <v-text-field class="custom-industry-label" hide-details solo
                                placeholder="Please specify the industry"
                                v-model="customIndustryName"/>

                  <h6 class="custom-industry-label  tw-mt-5 tw-mb-3">Role</h6>
                  <v-text-field class="custom-industry-label" v-model="selectedRole"
                                hide-details solo placeholder="Please specify the role"/>
                </div>
                <div v-else class="tw-flex tw-w-full tw-flex-col">
                  <div v-if="showRoles">
                    <div class="tw-py-6">
                      <button id="back" @click="showIndustry" class="pa-1 d-flex ml-2 backBtn align-center">
                        <vue-feather size="16" type="arrow-left" style="color: #475661"/>
                        <h6 class="ml-1">Back</h6></button>
                    </div>
                  <div v-if="industry.roles.length > 0 && industry.name === selectedIndustryName" class="tw-flex tw-flex-wrap  lg:tw-hidden tw-pb-5">
                    <div class=" tw-mr-5" v-for="role in industry.roles" :key="role" @click="handleSelectedRole(role)"
                         :class="[role === roleValue ? 'selected':'select']">{{role}}</div>
                    </div>
                    <div class="tw-mt-4" v-if="roleValue === 'Others'">
                      <p class="skills">Others</p>
                      <v-text-field
                          hide-details
                          placeholder="Please specify the Role"
                          v-model="customRole"
                          class="tw-w-2/3 tw-h-14"
                          solo
                          id="specifyOtherMobile"></v-text-field>
                    </div>
                  </div>
                  <div v-else>
                    <industry-btn v-for="industry in searchResult" :key="industry.name"
                                  :industry="industry" :selected-industry-name="selectedIndustryName"
                                  :title="industry.name"
                                  class="tw-mt-5 "
                                  @takeIndustry="getIndustry(industry)"
                                  @handleSelectedIndustryName="handleSelectedIndustryName(industry.name)"
                                  @handleSelectedRole="handleSelectedRole">

                    </industry-btn>
                  </div>
                </div>

              </div>
              <div class="tw-hidden lg:tw-flex lg:tw-flex-col scroll tw-pr-1" >
                <div v-for="industry in searchResult"
                     class="tw-mt-3"
                     :key="industry.name" @click="handleSelectedIndustryName(industry.name)"
                     :class="[selectedIndustryName === industry.name ? 'industry-selected' : 'industry-select-web']">
                  {{ industry.name }}
                </div>
              </div>
            </div>
          </div>
          <div class="tw-hidden md:tw-flex tw-w-full">
            <Loader style="margin-left: 10rem" v-if="loading" :loading="loading"/>
            <div v-else class="tw-hidden lg:tw-flex-col lg:tw-flex lg:tw-ml-10 tw-w-full">
              <div v-if="selectedIndustryName === 'Others'" class="tw-flex tw-w-full tw-flex-col">
                <div class="lg:tw-w-6/12" >
                  <h6 class="industry  tw-mb-3">Specify Industry and Role</h6>
                  <label class="tip">Input your specific industry and role of your choice</label>
                  <h6 class="custom-industry-label ">Industry</h6>
                  <v-text-field class="custom-industry-label" hide-details solo
                                placeholder="Please specify the industry" v-model="customIndustryName"/>

                  <h6 class="custom-industry-label  tw-mt-5 tw-mb-3">Role</h6>
                  <v-text-field class="custom-industry-label" v-model="selectedRole" hide-details solo
                                placeholder="Please specify the role"/>
                </div>
              </div>
              <div v-else class="tw-flex tw-w-full tw-flex-col">
                <small style="color: red" v-if="showError.state">{{ showError.message }}</small>
                <p v-if="handleReturnRoles && handleReturnRoles[0].roles.length > 1" class="industry">Roles</p>
                <div class=" tw-flex tw-flex-wrap " v-if="handleReturnRoles && handleReturnRoles[0].roles.length > 1">
                  <div v-for="role in handleReturnRoles[0].roles" class="tw-mr-5"
                       :key="role[0].name" @click="handleSelectedRole(role)"
                       :class="[role === roleValue ? 'selected':'select']">{{ role }}
                  </div>
                </div>
                <div class="lg:tw-w-9/12" v-if="roleValue === 'Others' || handleReturnRoles[0].roles.length === 1">
                  <p class="skills " :class="handleReturnRoles[0].roles.length === 1?'industry':'tw-mt-10'" style="letter-spacing: 0px">Specify role if not listed</p>
                  <v-text-field
                      hide-details
                      label="Please specify the role"
                      v-model="customRole"
                      class=" tw-w-80"
                      solo
                      id="specifyOther"></v-text-field>
                </div>
              </div>
            </div>
          </div>
          <div>
            <p class="tag" v-if="!$vuetify.breakpoint.mobile">Job Tags</p>
            <label class="tip">Please select up to 3 tags that applies to this job in order to enable better recommendation to matching talents.</label>
            <div class="tw-mt-5">
              <v-combobox
                  v-model="tag"
                  :items="jobTags"
                  multiple
                  chips
                  color="blue"
                  hide-details
                  solo

              >
                <template v-slot:selection="data">
                  <v-chip
                      v-bind="data.attrs"
                      :input-value="tag"
                      color="#0C8B99"
                      @click="data.select"
                  >
                    {{ data.item }}
                    <div @click="handleRemoveTag(data.item)">
                      <XIcon class="tw-w-4 tw-ml-2"/>
                    </div>
                  </v-chip>
                </template>
              </v-combobox>
            </div>
          </div>

        </div>
      </div>
    </template>
    <template #bottomNav>
      <bottom-navbar @click:next="handleUpdatePosting" :previous="false" :loading="buttonLoading"/>
    </template>
  </job-posting-layout>
</template>

<script>
import JobPostingLayout from "../layouts/JobPostingLayout";
import BottomNavbar from "../reuseables/BottomNavbar";
import StepCount from "../reuseables/StepCount";
import StepTitle from "../reuseables/StepTitle";
import IndustryBtn from "../reuseables/IndustryBtn";
import {getIndustriesAndRoles} from "@/services/api/APIService";
import Loader from "@/components/UIComponents/reusablesIcon/Loader";
import {getJobById, updateJob} from "../../../services/api/APIService";
import {XIcon} from "vue-feather-icons";

export default {
  name: "StepOne",
  components: {Loader, IndustryBtn, StepTitle, StepCount, BottomNavbar, XIcon, JobPostingLayout},
  // props:{
  //   selectable:{
  //     type: Function,
  //   },
  // },
  data() {
    return {
      showRoles:false,
      industries: [],
      selectedIndustryName: "",
      selectedRole: "",
      returnRoles: [],
      industry:{},
      roleValue: "",
      loading: false,
      showError: {
        message: "",
        state: false
      },
      customIndustryName: "",
      searchIndustry: '',
      searchResult: [],
      customRole: '',
      newIndustrie: [],
      buttonLoading: false,
      rolesForIndustry: [],
      tag:[],
      jobTags: [
        'Product Manager',
        'Product Designer (Ux)',
        'Frontend Software Engineer',
        'Backend Software Engineer',
        'Data Analyst',
        'Content Marketing Associate',
        'Seo Consultant',
        'System Administrator',
        'Visual Designer',
        'Software Training Facilitator',
        'Graphic Designer',
        'Mobile Developer',
        'Full Stack Developer',
        'IT Help Desk Technician',
        'Social Media Officer',
        'Web Developer',
        'Project Management Analyst',
        'Data Analyst',
        'Web Analytics Developer',
        'Digital Marketing Manager',
        'Network Support Engineer',
        'Regional Developer',
        'Content Manager',
        'Content Strategist',
        'Frontend React Native',
        'UI Designer',
        'Wifi Project Engineer',
        'Integration Engineer',
        'QA Engineer',
        'Solution And Research Engineer',
        'Technology Cloud Sales Representative',
        'Java Microservices Engineer',
        'Interaction Designer',
        'DevOps Engineer',
        'Technical Writer',
        'Customer Engagement Manager',
        'Software Developers',
        'WordPress Developer',
        'Frameworks Specialist',
        'Ruby On Rails Developer',
        'Python Developer',
        'Business Systems Analyst',
        'Blockchain Developer',
        'Maintenance Engineer And Machine Operator',
        'Data Architect',
        'Data Modeler',
        'Software Developer',
        'Data Scientist',
        'Cloud Architect',
        'Technical Lead',
        'Devops Manager',
        'Agile Project Manager',
        'Technical Account Manager',
        'Security Specialist',
        'QA (Quality Assurance) Specialist',
        'Game Developer',
        'Computer Graphics Animator',
        'Machine Learning Engineer',
        'Senior Backend Engineer'

      ]

    }
  },
  watch: {
    searchIndustry: function (newValue) {
      this.searchResult = [...this.industries.filter(industry => (industry.name.toLowerCase().indexOf(newValue.toLowerCase()) !== -1))]
    },
    'customRole': {
      handler: function (value) {
        this.selectedRole = value
      }
    }
  },
  methods: {
    showIndustry(){
      this.showRoles = false
    },
    getIndustry(data){
      this.industry=data
      this.showRoles = true
    },
    handleSelectedIndustryName(selectedIndustryName) {
      this.roleValue = ""
      this.selectedIndustryName = selectedIndustryName
      this.customRole = ''
      // if(this.industries && this.selectedIndustryName !== "") {
      //   this.rolesForIndustry = this.industries.filter(industry => industry.name === this.selectedIndustryName)
      //   console.log(this.rolesForIndustry[0].roles,"roles")
      // }
    },

    handleSelectedRole(selectedRole) {
      if (selectedRole === 'Others') sessionStorage.setItem('selectedRole', selectedRole)
      this.roleValue = selectedRole
      this.selectedRole = selectedRole === 'Others' ? '' : this.roleValue
    },
    async handleUpdatePosting() {
      this.buttonLoading = true
      let returnedSelectedDetails = JSON.parse(sessionStorage.getItem("returnedSelectedDetails"))
      if (!returnedSelectedDetails) {
        returnedSelectedDetails = {}
      }
      if (this.selectedRole === "") {
        this.showError.state = true
        this.showError.message = "You have not selected any Job Role"
        this.buttonLoading = false
      }
      else if(!this.tag.length){
        this.showError.state = true
        this.showError.message ="You have not selected any Job Tag"
        this.buttonLoading = false
      }
      else if(this.tag.length >= 4) {
        this.showError.state = true
        this.showError.message ="Job tag exceeds maximum number of 3"
        this.buttonLoading = false
      }
      else {
        this.showError.message = ""
        this.showError.state = false
        if (this.selectedRole === 'Others') {
          returnedSelectedDetails.role = this.customRole
        } else {
          returnedSelectedDetails.role = this.selectedRole
        }
        if (this.selectedIndustryName === 'Others') {
          returnedSelectedDetails.industry = this.customIndustryName
        } else {
          returnedSelectedDetails.industry = this.selectedIndustryName
        }

        returnedSelectedDetails.tag = this.tag
        if (returnedSelectedDetails.id) {
          const response = await updateJob(returnedSelectedDetails.id, returnedSelectedDetails)
          sessionStorage.setItem("returnedSelectedDetails", JSON.stringify(response.data))

        }
        this.buttonLoading = false
        sessionStorage.setItem("returnedSelectedDetails", JSON.stringify(returnedSelectedDetails))
        await this.$router.push({
          path: "2",
          name: "Job-stepTwo",
        })

      }
    },
    async updateWithSessionStorage() {
      const getReturnedSelectedDetails = JSON.parse(sessionStorage.getItem("returnedSelectedDetails"))
      let existingJob = this.industries.find(industry => industry.name === getReturnedSelectedDetails?.industry)
      this.selectedIndustryName = getReturnedSelectedDetails?.industry
      if (existingJob) {
        let aRole = null
        if (existingJob.roles.length) {
          existingJob.roles.forEach(role => {
            if (role === getReturnedSelectedDetails.role) {
              aRole = getReturnedSelectedDetails.role
            }
          })
        }
        if (aRole) {
          console.log(aRole)
          this.roleValue = getReturnedSelectedDetails.role
          this.selectedRole = getReturnedSelectedDetails.role
        } else {
          this.roleValue = ""
          this.selectedRole = getReturnedSelectedDetails.role
          this.customRole = getReturnedSelectedDetails.role
          this.tag = getReturnedSelectedDetails.tag
        }
      } else {
        this.selectedIndustryName = 'Others'
        this.customIndustryName = getReturnedSelectedDetails?.industry
        this.selectedRole = getReturnedSelectedDetails?.role
        this.tag = getReturnedSelectedDetails.tag

      }
    },
    handleRemoveTag(jobTags){
      this.tag= this.tag.filter(item => item !== jobTags)
    },
  },
  async created() {
    this.loading = true
    const getReturnedSelectedDetails = JSON.parse(sessionStorage.getItem("returnedSelectedDetails"))
    await getIndustriesAndRoles(this.$siteName()).then(res => {
      res.data.Others = {}
      const data = res.data;
      let dataKeys = Object.keys(data)
      this.industries = dataKeys.map(key => {
        this.loading = false
        return {
          name: key,
          roles: data[key]
        }
      })
      // this.formatIndustry()
      this.searchResult = this.industries
      this.industries.forEach(function (industry) {
        industry.roles.push('Others')
      })
    })
        .catch(() => {
          // this.$displaySnackbar({message:err.response.data.detail, success:false})
          this.loading = false
        })

    if (this.$route.query.jobId || getReturnedSelectedDetails?.id) {
      let data = {}
      data.id = this.$route.query.jobId || getReturnedSelectedDetails.id
      data.site_name = this.$siteName()
      await getJobById(data).then(res => {
        this.selectedIndustryName = res.data.industry
        sessionStorage.setItem("returnedSelectedDetails", JSON.stringify(res.data))
        let existingJob = this.industries.find(industry => industry.name === res.data.industry)
        if (existingJob) {
          let aRole = null
          existingJob.roles.forEach(role => {
            if (role === res.data.role) {
              aRole = res.data.role
            }
          })
          if (aRole) {
            this.roleValue = res.data.role
            this.selectedRole = res.data.role
          } else {
            this.roleValue = ""
            this.selectedRole = res.data.role
            this.customRole = res.data.role
            this.tag = res.data.tag
          }
        } else {
          this.selectedIndustryName = ''
          this.customIndustryName = res.data.industry
          this.selectedRole = res.data.role
          this.tag = res.data.tag
        }
      }).catch(err => {
        console.log(err.response.data.detail)
        this.$displaySnackbar({message: err.response.data.detail, success: false})
      })

    } else {
      await this.updateWithSessionStorage()
    }
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    handleReturnRoles() {
      if (this.industries && this.selectedIndustryName !== "") {
        return this.industries.filter(industry => industry.name === this.selectedIndustryName)
      }
    },

  }
}
</script>

<style scoped lang="scss">
.custom-industry-label {
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #1E323F;
}

.tip {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  align-items: center;
  color: #9CABB5;
}
.tag{
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #5A5A5A;
}

.industry {
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  align-items: center;
  color: #1E323F;
}

.webScrollx {
  max-height: 40vh;
  overflow-y: scroll;
  overflow-x: hidden;
  @media screen and (max-width: 1024px) {
    max-height: none;
  }

  .otherSpecify {
    background: linear-gradient(0deg, #FFFFFF, #FFFFFF);
    border: 1px solid #9CABB5;
    box-sizing: border-box;
    border-radius: 6px;
    font-family: DM Sans, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #475661;
  }

  ::v-deep .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot:not(.v-input--is-disabled) {
    border: none !important;
  }
}

.industry-select-web {
  padding: 10px 15px;
  width: 100%;
  min-height: 56px;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}
.backBtn{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #475661;
}

.industry-select-web:hover {
  padding: 10px 15px;
  width: 100%;
  min-height: 56px;
  background: #F7FCFF;
  border: 1px solid #008EEF;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  align-items: center;
  color: #008EEF;
  cursor: pointer;

}


.industry-selected {
  padding: 10px 15px;
  width: 100%;
  min-height: 56px;
  background: #F7FCFF;
  border: 1px solid #008EEF;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #008EEF;
  cursor: pointer;
}
.industry-btn {
  padding: 10px;
  box-sizing: border-box;
  border-radius: 8px;
  cursor: pointer;
}

.industry-btn-active {
  border: 1px solid #F3F3F3;
}

.industry-text {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  align-items: center;
  color: #475661;
}
::v-deep .v-chip .v-chip__content {
  color: white;
}

</style>
